@import "_variables.styl"

transition(time)
     transition-property all
     transition-duration time
     transition-timing-function ease-in-out

placeholder(color, opacity, font-size, font-family)
     &::-webkit-input-placeholder
          color color
          opacity opacity
          font-size font-size
          font-family font-family
          text-transform uppercase
          font-weight 500
     &:-moz-placeholder
          color color
          opacity opacity
          font-size font-size
          font-family font-family
          text-transform uppercase
          font-weight 500
     &::-moz-placeholder
          color color
          opacity opacity
          font-size font-size
          font-family font-family
          text-transform uppercase
          font-weight 500
     &:-ms-input-placeholder
          color color
          opacity opacity
          font-size font-size
          font-family font-family
          text-transform uppercase
          font-weight 500

button-bg-1(color)
     background color
     &:hover
          background darken(color, 15%)

button-bg-2(color-1, color-2)
     background color-1
     color color-2
     &:hover
          background color-2
          color color-1

text-elipsis(width)
     max-width width
     white-space nowrap
     overflow hidden
     text-overflow ellipsis

size(width, height)
     width width
     height height

flex(flex-direction, align-horizontal, align-vertical)
     display flex
     flex-direction flex-direction
     justify-content align-horizontal
     align-items align-vertical

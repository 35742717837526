@keyframes main-screen-animate
	0%
		opacity 0
		transform rotate(15deg) scale(1.1)
	20%
		opacity 1
		transform rotate(-15deg) scale(1.1)
	40%
		transform rotate(10deg) scale(1.2)
	60%
		transform rotate(-10deg) scale(1.2)
	80%
		transform rotate(5deg) scale(1.1)
	90%
		transform rotate(-5deg) scale(1.1)
	100%
		transform rotate(0deg) scale(1)


.main-screen-animate
	animation main-screen-animate 0.7s
	animation-fill-mode forwards
	animation-delay 0.3s


@keyframes visible-animate
	0%
		opacity 0
		transform scale(0)
	100%
		opacity 1
		transform scale(1)

.animate-visivle
	animation visible-animate 1s
	animation-fill-mode forwards
	animation-delay 0.5s


@keyframes btn-trem-animate
	0%
		transform translateX(-5px)
	20%
		transform translateX(5px)
	40%
		transform translateX(-3px)
	60%
		transform translateX(3px)
	80%
		transform translateX(-1px)
	90%
		transform translateX(1px)
	100%
		transform translateX(0px)

.btn-trem-animate
	animation btn-trem-animate 3s infinite
	animation-fill-mode forwards
	animation-delay .5s

@keyframes rotate-img
	0%
		transform rotate(0deg)
	100%
		transform rotate(360deg)

@keyframes rotate-img-2
	0%
		transform rotate(-45deg)
	100%
		transform rotate(360deg)

@keyframes rotate-img-3
	0%
		transform rotate(90deg)
	100%
		transform rotate(360deg)

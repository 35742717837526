.info
	padding 65px 15px
	flex(row, center, center)
	background #282726
	@media (max-width: 767px)
		flex-direction column
		padding 50px 0
	&__item
		flex(column, center, center)
		margin 0 9% 0 0
		text-align: center;
		@media (max-width: 767px)
			margin 0 0 40px
		&:last-of-type
			margin: 0
		&--img
			position relative
			size(100%, 215px)
			margin 0 0 22px
			img
				max-width 100%
				height: auto
		p
			text-transform: uppercase
			font-size 18px
			line-height 1.5
			color #fff
			span
				border-bottom 2px dotted #f6dc49
				margin-top 3px

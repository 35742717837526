*
	margin 0
	padding 0
	box-sizing border-box
	&:after
	&:before
		box-sizing border-box

h1
h2
h3
h4
h5
h6
ul
li
a
img
input
button
textarea
	margin 0
	padding 0
	overline none

table
	border-collapse collapse
	border-spacing 0

img
	border 0


.cooperation
	padding 50px 0 100px
	background: #fff
	@media (max-width 767px)
		padding 30px 0 50px
	&__title
		text-align: center
		font-size: 24px
		color #373737
		letter-spacing 0.05em
		margin 0 0 40px
		span
			display: block
			text-align: center
			font-size: 46px
			letter-spacing 0.025em
			text-transform: uppercase
			font-weight: 700
			margin 5px 0 0
			@media (max-width 767px)
				font-size: 30px
	&__list
		flex(row, space-between, flex-start)
		flex-flow row wrap
		width 100%
		max-width 1110px
		margin 0 auto
		position relative
		@media (max-width 1100px)
			flex-direction column
			align-items center
			padding 0 20px
		@media (max-width 1100px)
			padding 0 10px
		&:before
			content ''
			position absolute
			width 100%
			height 1px
			border-top 1px solid #999
			top 44px
			left 0
			@media (max-width 1100px)
				display: none
		&:after
			content ''
			position absolute
			width 1px
			height calc(100% - 50px)
			border-left 1px solid #999
			top 44px
			left 50%
			transform translateX(-50%)
	&__item
		flex-basis 530px
		margin 0 0 50px
		@media (max-width 1100px)
			width  100%
			flex-basis auto
		&.first
			.cooperation__item--content
				min-height 450px
				@media (max-width 1100px)
					min-height 100%
		&.second
			.cooperation__item--content
				min-height 450px
				@media (max-width 1100px)
					min-height 100%
		&.third
			flex-basis 100%
			margin-bottom 0
			.cooperation__item--content
				flex(row, flex-start, flex-start)
				@media (max-width 767px)
					flex-direction column
		&--header
			background: #ffffff
			padding 5px
			position relative
			z-index 2
			box-shadow 0 0 70px rgba(0,0,0,.1)
		&--header-content
			border 1px dotted #d2d2d2
			height 78px
			flex(row, space-between, center)
			padding 0 20px 0 50px
			@media (max-width 767px)
				padding 0 10px
		&--title
			flex(row, flex-start, center)
			p
				position relative
				&:first-of-type
					margin 0 10px 0 0
			p.name
				font-size: 30px
				color #221f1f
				text-transform: uppercase
				letter-spacing 0.05em
				z-index 2
				position relative
				&.bold
					font-weight: 900
				@media (max-width 767px)
					font-size: 24px
				&.eng
					@media (max-width 767px)
						font-size: 20px
			span.name-bg
				font-weight: 900
				position absolute
				text-transform: uppercase
				color #f4f4f4
				font-size 66px
				z-index -1
				left: 50px
				top: -18px
				@media (max-width 767px)
					font-size 32px
					top -4px
				&.sub
					left 0
					font-size 63px
				&.eng
					@media (max-width 767px)
						font-size: 24px
						top -1px
			p.who
				// font-weight: 900
				text-transform: uppercase
				color #221f1f
				font-size 30px
				z-index 2
				position relative
				@media (max-width 767px)
					font-size 24px
				&.eng
					@media (max-width 767px)
						font-size 20px
			span.border
				// position absolute
				// width 100%
				// height: 5px
				// background: #f9e15b
				// bottom 2px
				// left: -1px
				// z-index 1
				font-weight 900
				box-shadow 0 -10px 0 #f9e15b inset
				&.ru
					margin-left 10px
		&--number
			size(44px, 44px)
			border-radius 50%
			background: #373737
			color #f9e15b
			font-weight 700
			font-size 30px
			text-align: center
			line-height 44px
			@media (max-width 767px)
				size(35px, 35px)
				line-height 35px
				font-size 22px
				min-width 35px
				position relative
				z-index 10
		&--content
			background: #eeeeee
			width calc(100% - 60px)
			margin 0 30px
			padding 35px 30px
			position relative
			z-index 2
			@media (max-width 767px)
				margin: 0
				width 100%
				padding 20px 10px
		&--content-row
			flex(row, flex-start, flex-start)
			margin 0 0 13px
			@media (max-width 767px)
				margin 0 0 5px
		&--content-row-number
			size(26px, 26px)
			background: #ffffff
			margin 0 15px 0 0
			min-width 26px
			border-radius 50%
			line-height 26px
			text-align: center
			color #878787
			font-size 16px
			font-weight 700
		&--content-text
			padding 0 4px 0 0
			line-height 24px
			color #373737
			&.bold
				font-weight 600
		&--content-footer
			margin 25px 0 0
			p
				font-size: 12px
				color #717171
				line-height 1.5
		&--content-column
			width 100%
			max-width 440px
			margin 0 100px 0 0
			&:last-of-type
				margin-right 0
		&--content-payment
			position relative
			display: inline-block
			p.name
				font-size 16px
				font-weight 600
				color #221f1f
				line-height 1.5
				position relative
				z-index 2
			span.border
				// position absolute
				// width 100%
				// height: 3px
				// background: #f9e15b
				// bottom 5px
				// left: -1px
				// z-index 1
				font-weight 900
				box-shadow 0 -5px 0 #f9e15b inset

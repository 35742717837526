// Colors
base-text-color = #000
gray = #333
blue = #1f4df8
red = #f9091f

.padding-left-none
    padding-left 0


.padding-right-none
    padding-right 0

.padding-right-left-none
    padding-right 0
    padding-left 0

.clearfix
    &:before
    &:after
         content " "
         display table
    &:after
         clear both
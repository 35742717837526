body
	min-width 320px
	position relative
	font-size 14px
	font-family 'Raleway', sans-serif
	font-weight 400
	color base-text-color
	line-height 1
	overflow-x hidden
	&.hidden
		overflow hidden

input
	required valid
	outline none
	transition(0.5s)
	&:focus
		required invalid

textarea
	required valid
	color base-text-color
	&:focus
		required invalid
		color base-text-color

button
input[type='submit']
	cursor pointer
	transition(0.5s)
	background transparent
	border 1px solid base-text-color
	&:focus
	&:active
		outline none

a
	text-decoration none
	color #333
	cursor pointer
	transition(0.5s)
	&:hover
		text-decoration none

li
	list-style none

.form-contacts
	background #ffe972
	padding 100px 10%
	@media (max-width 1100px)
		padding 50px 20px 70px
	@media (max-width 767px)
		padding 50px 10px 30px
	&__title
		text-align: center
		margin 0 0 50px
		p
			text-align: center
			font-size: 46px
			color #373737
			font-weight: 900
			letter-spacing 0.025em
			text-transform: uppercase
			position relative
			display inline-block
			margin 0 0 5px
			@media (max-width 900px)
				font-size: 30px
			@media (max-width 767px)
				font-size: 20px
			&:last-of-type
				display: block
				margin-bottom: 0
			& > span:first-of-type
				position relative
				z-index 2
			span.border
				box-shadow 0 -20px 0 #fff inset
				@media (max-width 767px)
					box-shadow 0 -10px 0 #fff inset
	&__content
		flex(row, center, flex-start)
		@media (max-width 900px)
			flex-direction column-reverse
			align-items center
	&__img
		position relative
		margin 0 30px 0 0
		@media (max-width 767px)
			margin 0
			max-width 100%
		img
			position absolute
			z-index 2
			top -30px
			right 50px
			max-width 100%
			height: auto
			@media (max-width 767px)
				top: 0
				right: 0
				position relative
		span.bg
			display: inline-block
			position relative
			z-index 1px
			size(370px, 333px)
			background: #fff0a2
			@media (max-width 767px)
				max-width 100%
				height 100%
				position absolute
				top: 0
				left: 0

	&__form
		width 100%
		max-width 370px
		@media (max-width 900px)
			margin 0 0 50px
		@media (max-width 767px)
			margin 0 0 30px
		.form-group__input
			margin 0 0 15px
			position relative
			&:before
				content '\f0e0'
				position absolute
				top 50%
				transform translateY(-50%)
				left 15px
				font-family FontAwesome
				font-size: 14px
				color #373737
			&.name:before
				content '\f007'
			&.skype:before
				content '\f17e'
			&.company:before
				content '\f0b1'
			input
				size(100%, 38px)
				background: #fff0a2
				border 1px solid transparent
				padding 0 10px 0 45px
				placeholder(#373737, 1, 12px, 'Ubuntu')
				&:hover,
				&:focus
					border-color #373737
		.form-group__btn
			size(100%, 66px)
			background: #373737
			color #fff
			font-size 16px
			font-weight 700
			border none
			&:hover
				background lighten(#373737, 20%)
				animation none
		.form-group__select
			position relative


.nice-select.selectprogramm
	margin 0 0 15px
	width 100%
	height 38px
	background #fff0a2
	border none
	padding-left: 45px
	position relative
	font-family 'Ubuntu'
	font-weight 500
	text-transform uppercase
	font-size 12px
	color #373737
	&:before
		content '\f044'
		position absolute
		top 50%
		transform translateY(-50%)
		left 15px
		font-family FontAwesome
		font-size: 16px
	.list li
		border-bottom 1px solid #e8d467
		font-size 12px
		font-weight 500
		color #373737
		height 38px
		line-height 38px
		&:last-of-type
			border-bottom none

.nice-select.open .list
	width 100%
	background #fff0a2

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus
	background-color #fff4bd

.nice-select .option.selected
	font-weight normal

.nice-select:after
	position absolute
	content '\f0d7'
	font-family FontAwesome
	right 10px
	top 50%
	transform translateY(-50%)
	font-size: 16px
	color #373737
	border none
	size(auto, auto)
	margin 0
	transform-origin: 50% 0%

.nice-select.open:after
	transform rotate(-90deg) translateY(-50%)

.box-tultip-name,
.box-tultip-email,
.box-tultip-programs {
  font-family: 'Ubuntu', sans-serif;
  display: none;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.17);
  color: #2f2f2f;
  line-height: 21px;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  z-index: 10;
  @media (max-width: 767px) {
    font-size: 12px;
    min-width: 260px;
    padding: 15px 25px 15px 40px;
    line-height: 16px;
  }
  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    content: '!';
    display: inline-block;
    font-size: 20px;
    color: #fff;
    background-color: #bf360c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 0;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
  }
  &:after {
    position: absolute;
    content: '';
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    transform: rotate(-135deg);
    box-shadow: 5px 3px 10px -3px rgba(0,0,0,.17);
  }
}

.propose
	height 460px
	background url(/img/affiliate_programs/propose-bg.png) 0 0 no-repeat
	background-size cover
	background-attachment fixed
	flex(column, center, center)
	color #fff
	position relative
	padding 0 15px
	text-align center
	@media (max-width 767px)
		padding 50px 15px
		height 100%
	&__description
		font-size: 24px
		line-height 1
		margin 0 0 30px
		letter-spacing 0.1em
		// border-bottom 5px solid #ffe972
		span
			box-shadow 0 -4px 0 #ffe972 inset
		@media (max-width 767px)
			text-align: center
			font-size: 18px
			display: inline-block
	&__title
		font-size 46px
		font-weight 900
		text-transform uppercase
		max-width 990px
		text-align center
		line-height 1.2
		letter-spacing 0.025em
		@media (max-width 767px)
			font-size: 24px

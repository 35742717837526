.main-screen
	height 100vh
	background url(/img/affiliate_programs/main-screen-bg.png) 0 0 no-repeat
	background-size cover
	background-attachment fixed
	position relative
	flex(row, center, center)
	@media (max-width: 960px)
		padding 0 60px
	@media (max-width: 767px)
		padding 0 20px
		// min-height 100vh
		// height 100%
	// @media (max-width: 360px)
	// 	padding 100px 50px
	&__logo
		position absolute
		top 70px
		left 50%
		transform translateX(-50%)
		@media (max-width: 767px)
			top 15px
			left 15px
			transform translateX(0)
			img
				max-width 150px
	&__content
		border 1px dotted #a3a2a1
		padding 40px 15px 80px
		max-width 830px
		width 100%
		text-align: center
		position relative
		@media (max-width: 767px)
			padding 40px 20px
		&.eng
			@media (max-width: 767px)
				padding 20px
		&--decor-corner-1
			position absolute
			top -37px
			right -37px
			size(33px, 33px)
			border-top 10px solid #fff
			border-right 10px solid #fff
			@media (max-width: 767px)
				display none
		&--decor-corner-2
			position absolute
			bottom -37px
			left -37px
			size(33px, 33px)
			border-bottom 10px solid #fff
			border-left 10px solid #fff
			@media (max-width: 767px)
				display none
		&--decor-line-1
			position absolute
			top 140px
			right -6px
			size(11px, 80px)
			background #fff
		&--decor-line-2
			position absolute
			top 110px
			left -41px
			size(81px, 10px)
			background: #fff
			@media (max-width: 767px)
				display none
		& > p:first-of-type
			display inline-block
			background #f6dc49
			padding 10px 20px
			font-size 12px
			text-transform uppercase
			color #373737
			font-weight: 700
			letter-spacing 0.1em
			margin 0 0 55px
		& > p:nth-of-type(2)
			font-size 24px
			color #fff
			letter-spacing 0.05em
			margin 0 0 10px
			@media (max-width: 360px)
				font-size 18px
		& > p:last-of-type
			font-size: 46px
			text-transform: uppercase
			font-weight: 900
			color #fff
			letter-spacing 0.025em
			margin 0 0 40px
			@media (max-width: 767px)
				font-size 34px
			@media (max-width: 360px)
				font-size: 24px
			&.eng
				font-size: 38px
				@media (max-width 500px)
					font-size: 20px
		& > a
			display inline-block
			padding 25px 10px
			width 100%
			max-width 250px
			background #373737
			color #fff
			font-size 16px
			font-weight 700
			&:hover
				background lighten(#373737, 10%)
				5168742350722991

.button__next-section
	position absolute
	left 50%
	transform translateX(-50%)
	bottom -28px
	size(56px, 56px)
	border-radius 50%
	border none
	button-bg-2(#373737, #ffe972)
	font-size: 46px
	line-height 1

.lang
	font-family 'Ubuntu', sans-serif
	position absolute
	top 30px
	right 30px
	display flex
	align-items center
	font-size 15px
	@media (max-width: 767px)
		top 15px
		right 15px
	a
		font-size 15px
		line-height 1
		text-transform uppercase
		color #221f1f
		display inline-block
		opacity .3
		transition .2s
		&:hover
			opacity 1
		&.active
			opacity 1
	span
		display inline-block
		margin 0 5px

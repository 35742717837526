.popup
  position fixed
  width 100%
  height 100vh
  top 0
  left 0
  right 0
  bottom 0
  background-color rgba(#000,0.3)
  display none
  z-index 99999
  &.close
    display none !important
  .message
    position absolute
    display: none
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-color #fff
    text-align center
    padding 85px 75px
    width 100%
    max-width 430px
    z-index 99999
    @media (max-width 767px)
      padding 30px 25px
      max-width 290px
    img
      margin 0 0 20px
      @media (orientation landscape) and (max-width 767px)
        display none
    &__title
      margin 0 0 30px
      color #1d1d1d
      font-size 34px
      font-weight 900
      text-transform uppercase
      @media (max-width 767px)
        margin-bottom 15px
        font-size 28px
      span
        display block
        font-size 16px
        font-weight 300
        margin-top 10px
        @media (max-width 767px)
          font-size 14px
          margin-top 5px
    &__desc
      color #5a5a5a
      font-size 16px
      font-weight 300
      line-height 24px
      @media (max-width 767px)
        font-size 14px
      p
        margin-bottom 30px
        @media (max-width 767px)
          margin-bottom 15px
        &:last-child
          margin-bottom 0
    &__link
      color #5a5a5a
    .close-message
      display inline-block
      position absolute
      top 15px
      right 15px
      width 20px
      height 20px
      border 0
      background rgba(0, 0, 0, 0)
      color #2d2d2d
      line-height 20px
      padding 0
      font-size 18px
      cursor pointer
      z-index 10
      .fa-times
        position absolute
        top 2px
        right 8px
        width 3px
        height 15px
        background-color #2d2d2d
        &:before
          display none
        &_1
          transform rotate(45deg)
        &_2
          transform rotate(135deg)
